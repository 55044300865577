import * as React from "react"

// markup
const HeroComponent = () => {
  return (
    <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">
      <div className="carousel-item active">
        <div className="carousel-container">
          <h2 className="animate__animated animate__fadeInDown">A Cutting-Edge Solution for Investment making</h2>
          <p className="animate__animated animate__fadeInUp">
            Start your investment today and benefit from the unique access to the opportunities of  the most advanced crowdfunding and P2P lending platforms through Kvotum.
          </p>
        </div>        
      </div>
      <div className="carousel-item">
        <div className="carousel-container">
          <h2 className="animate__animated animate__fadeInDown">Investing Has Never Been Easier</h2>
          <p className="animate__animated animate__fadeInUp">
            We strive to provide our clients with great additions to their investment portfolios. We partner with the best lending platforms to provide safe, profitable and relaible deals.
          </p>
        </div>
      </div>
      <div className="carousel-item">
        <div className="carousel-container">
          <h2 className="animate__animated animate__fadeInDown">Various Investment Options</h2>
          <p className="animate__animated animate__fadeInUp">
            Kvotum allows you to do your investment in multiple platforms with a wide range of payment options, including cryptos like Bitcoin, Ethereum, and fiat currencies.
          </p>
        </div>
      </div>
      <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
        <span className="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  )
}

export default HeroComponent
import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import HeroComponent from "../components/hero.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const IndexPage = () => {
  
    useScript('static/js/jquery.min.js')
    useScript('static/js/bootstrap.bundle.js')
    useScript('static/js/jquery.easing.min.js')
    useScript('static/js/venobox.min.js')
    useScript('static/js/jquery.waypoints.min.js')
    useScript('static/js/counterup.min.js')
    useScript('static/js/owl.carousel.min.js')
    useScript('static/js/isotope.pkgd.min.js')
    useScript('static/js/aos.js')
    useScript('static/js/main.js')

  return (
    <>
      <Head />
      <header id="header" className="fixed-top header-transparent">
        <HeaderComponent />
      </header>
      <section id="hero" className="d-flex justify-cntent-center align-items-center">
        <HeroComponent />
      </section>
      <main id='main'>
        <a id="cards"></a>
        <section className="services">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up">
                <div className="icon-box icon-box-pink">
                  <div className="icon"><i className="bx bx-data"></i></div>
                  <h4 className="title">Simple Process</h4>
                  <p className="description">Kvotum integrates smoothly into lending platforms and maintains data simultaneously.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                <div className="icon-box icon-box-cyan">
                  <div className="icon"><i className="bx bx-file"></i></div>
                  <h4 className="title">Contract-Based Deals</h4>
                  <p className="description">Every purchase has its own particular contract, which is arranged uniquely for its individual case.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box icon-box-green">
                  <div className="icon"><i className="bx bx-home"></i></div>
                  <h4 className="title">Finding the Best Option for You is a Piece of Cake!</h4>
                  <p className="description">At Kvotum, you are able to pick from various options with a number of currencies without any hassle.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box icon-box-blue">
                  <div className="icon"><i className="bx bx-bitcoin"></i></div>
                  <h4 className="title">Maximum Security - Minimum Risk</h4>
                  <p className="description">Thanks to our partnerships with top-tier platforms, Kvotum minimizes risks for your investments.</p>
                </div>
              </div>

            </div>

          </div>
        </section>
        <a id="sections"></a>
        <section className="features">
          <div className="container">
            <div className="section-title">
              <h2>How Kvotum Works</h2>
              <p>Kvotum is a marketplace for secondary loan investments that originate from its partnering crowdfunding and P2P lending platforms.</p>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-md-5">
                <img src="static/features-1.svg" className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-4">
                <h3>Kvotum Collaborates with the Most Reputable Crowdfunding Platforms and Projects</h3>
                <p>
                  We pay special attention to providing you with safe and reliable opportunities. That’s why we have partnered up with the most trustworthy crowdfunding, crowdinvesting and P2P lending  providers in the industry.
                </p>
                <ul>
                  <li><i className="icofont-check"></i> Kvotum offers exclusive deals, with varying terms and rates</li>
                  <li><i className="icofont-check"></i> All Kvotum partners provide their investors with enhanced liquidity on all investment projects</li>
                </ul>
              </div>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-md-5 order-1 order-md-2">
                <img src="static/features-2.svg" className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-5 order-2 order-md-1">
                <h3>The Most Comprehensive Marketplace for You</h3>
                <p>
                  As Kvotum, we aim to provide you with as solid investment options as possible. Here, you can use both long-term and short-term investing instruments from reputable European crowdfunding and P2P lending platforms. Regardless of your preference, you will be able to pick the options that suit your investment needs.
                </p>
              </div>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-md-5">
                <img src="static/features-3.svg" className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-5">
                <h3>Smooth Acquisition Process</h3>
                <p>At Kvotum, the only thing you need to do is to make your decision. Afterwards, we will take care of everything, including agreements, data exchange, transactions and more without any commission fee!</p>
              </div>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-md-5 order-1 order-md-2">
                <img src="static/features-4.svg" className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-5 order-2 order-md-1">
                <h3>Take Your Process With You
                </h3>
                <p className="font-italic">
                  Once you join Kvotum, you will be able to track your steps, observe your previous investments, and obtain comprehensive information about your progress.
                </p>
                <p>
                  We provide you with a top-notch back office that helps you to benefit from our services adequately. Furthermore, we want you to manage your investments with a mind at peace without any worry about the security of your funds or personal information. Therefore, we have developed our site with cutting-edge security standards.
                </p>
              </div>
            </div>

          </div>
        </section>
      </main>
      <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
      </footer>
    </>
  )
}

export default IndexPage